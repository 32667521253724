
import { onFetchAsync } from '@scayle/storefront-nuxt2'
import {
  computed,
  defineComponent,
  useRoute,
  useRouter,
} from '@nuxtjs/composition-api'
import { useCms } from '~/composables/useCms'
import Accordion from '~/components/cms/Accordion.vue'
import Button from '~/components/ui/buttons/Button.vue'

export default defineComponent({
  components: {
    Accordion,
    Button,
  },
  setup() {
    const { data, fetchBySlug } = useCms(`useCms-faq2`)
    const route = useRoute()
    const router = useRouter()

    onFetchAsync(async () => {
      await fetchBySlug({
        slug: `s/faq`,
        callingComponent: 'FAQs.vue',
        route: route.value.fullPath,
      })
    })

    const navigateToFaq = () => {
      router.push('/s/faq')
    }

    const faqs = computed(
      () => data.value?.content?.content?.[0] ?? { entries: [] }
    )

    const accordionLength = 5

    const firstHalfOfEntries = computed(() => {
      const left = { ...faqs.value }
      left.entries = faqs.value?.entries?.slice(0, accordionLength)
      return left
    })

    const secondHalfOfEntries = computed(() => {
      const right = { ...faqs.value }
      right.entries = faqs.value?.entries?.slice(
        accordionLength,
        faqs.value.entries?.length < accordionLength * 2
          ? faqs.value.entries?.length
          : accordionLength * 2
      )
      return right
    })

    return {
      headline: computed(() => data?.value?.content?.headline),
      subline: computed(() => data?.value?.content?.subline),
      numberOfEntries: computed(
        () => data.value?.content.content[0].entries?.length || 0
      ),
      firstHalfOfEntries,
      secondHalfOfEntries,
      navigateToFaq,
    }
  },
})
