
import {
  computed,
  defineComponent,
  useAsync,
  useRoute,
} from '@nuxtjs/composition-api'
import Link from '~/components/ui/links/Link.vue'
import { useCmsStories } from '~/composables/useCms'
import USPList from '~/components/usps/USPList.vue'

export default defineComponent({
  name: 'USPBox',
  components: {
    USPList,
    Link,
  },
  props: {
    isBasketPage: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { data: uspData, fetchByFolder } = useCmsStories('usps')
    const route = useRoute()

    useAsync(
      async () =>
        await fetchByFolder({
          folder: 'usps',
          callingComponent: 'USPBox.vue',
          route: route.value.fullPath,
          cacheOptions: { ttl: 3600, cacheKeyPrefix: 'USPS' },
        })
    )
    return {
      uspData: computed(() => uspData.value?.[0]?.content?.usp),
    }
  },
})
