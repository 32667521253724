import {computed, Ref, unref, useContext} from '@nuxtjs/composition-api'
import {identity} from '~/helpers/predicates'

/**
 *
 * @param sizes {string} - image size breakpoints with the follwing formats `xs:640px sm:768px md:1024px lg:1280px xl:1536px xxl:1920px` also `320px:640px 1024px:768px `
 */
export const getImageSizes = (sizes: string | Ref<string>) => {
  const {$img} = useContext()

  return computed(() => {
    const splitSizes = (unref(sizes) || '').split(' ').filter(identity)

    const querySizes = splitSizes.map((size: string) => {
      const [viewAlias, imageWidth] = size.split(':')

      // Parse both lg:500 and 1200px:500
      const viewWidth =
        $img.options.screens[viewAlias] || viewAlias.replace('px', '')
      return {imageWidth, query: `(max-width: ${viewWidth}px) ${imageWidth}`}
    })

    const finalSize = splitSizes.slice(-1)[0].split(':')[1]

    return {
      querySizes: [...querySizes.map(qs => qs.query), finalSize],
      imageSizes: querySizes.map(qs => parseInt(qs.imageWidth))
    }
  })
}
