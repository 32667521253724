
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import {
  computed,
  defineComponent,
  ref,
  onMounted,
  PropType,
  useRoute,
} from '@nuxtjs/composition-api'
import USP from '~/components/usps/USP.vue'
import { useCmsStories } from '~/composables/useCms'
import {onFetchAsync} from '@scayle/storefront-nuxt2';

export default defineComponent({
  name: 'USPs',
  components: {
    VueSlickCarousel,
    USP,
  },
  props: {
    showBorder: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
  },
  setup() {
    const componentMounted = ref(false)
    const { data: uspData, fetchByFolder } = useCmsStories('usps')
    const route = useRoute()

    onFetchAsync(
      async () => {
        await fetchByFolder({
          folder: 'usps',
          callingComponent: 'USPSSlider.vue',
          route: route.value.fullPath,
          cacheOptions: {
            ttl: 3600,
            cacheKeyPrefix: 'USPS',
          },
        })
      }
    )

    onMounted(() => {
      componentMounted.value = true
    })

    return {
      uspData: computed(() => uspData.value?.[0]?.content?.usp),
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
          },
        },
      ],
      componentMounted,
    }
  },
})
