
import {
  computed,
  defineComponent,
  useRoute,
  onMounted,
  ref
} from '@nuxtjs/composition-api'
import {onFetchAsync} from '@scayle/storefront-nuxt2'
import OutletTeaser from '~/components/landing-page/OutletTeaser.vue'
import {useCms} from '~/composables/useCms'
import FAQs from '~/components/cms/FAQs.vue'
import USPSlider from '~/components/usps/USPSlider.vue'
import USPBox from '~/components/landing-page/USPBox.vue'
import LandingPageData from '~/@types/cms/landingPageData'
import DealPreviewGrid from '~/components/landing-page/DealPreviewGrid.vue'
import {getGenderParamFromUser} from '~/helpers/user'
import useHomeSeo from '~/composables/useHomeSeo'
import { useExperiment } from '~/composables/optimizely/useExperiment'
import cookieConsent from '~/helpers/cookieConsent'

export default defineComponent({
  name: 'HomePage',
  type: 'category',
  components: {
    FAQs,
    OutletTeaser,
    USPSlider,
    USPBox,
    DealPreviewGrid
  },
  layout: 'public',
  middleware({redirect, app, route}: any) {
    const user = app.$cookies.get('ayc-user')

    if (user && user.isAuthenticated) {
      redirect(`/deals/${getGenderParamFromUser(user)}`, route.query)
    }
  },

  setup() {
    const {data, fetchBySlug} = useCms('landingpage')
    const route = useRoute()
    const {activate} = useExperiment()

    const experiment = ref('') // ref<string | null>(getFeatureVariantIfPresent('ab_log_reg'))

    useHomeSeo()

    const consent = cookieConsent('welcome_page', ['targeting', 'op'], new Map())
      .optIn(() => {
        setTimeout(() => {
          activate('aa_welcome_page')
        }, 1000)
      }, {alias: 'activate_welcome_page'})

    onMounted(() => {
      consent.invoke()
    })

    onFetchAsync(async () => {
      await fetchBySlug({
        slug: 'landingpage',
        callingComponent: 'index.vue (Landingpage)',
        route: route.value.fullPath,
        cacheOptions: {
          ttl: 3600,
          cacheKeyPrefix: 'Landingpage'
        }
      })
    })

    return {
      experiment,
      outletTeaserData: computed(
        () =>
          ((data.value)?.content as LandingPageData['content'])?.OutletTeaser?.[0]
      ),
      dealPreviewsData: computed(
        () =>
          ((data.value)?.content as LandingPageData['content'])?.DealPreviewGrid?.[0]?.dealPreview
      )
    }
  },
  head: {},
  meta: {
    pageType: 'homepage'
  }
})
