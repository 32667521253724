
import { defineComponent, PropType } from '@nuxtjs/composition-api'
import DealPreview from '~/components/landing-page/DealPreview.vue'
import { DealPreviewData } from '~/@types/cms/landingPageData'

export default defineComponent({
  components: { DealPreview },
  props: {
    dealPreviews: {
      type: Array as PropType<DealPreviewData[]>,
      required: true,
    },
  },
})
