
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// eslint-disable-next-line import/order
import {computed, defineComponent, PropType} from '@nuxtjs/composition-api'
import {OutletTeaserData} from '~/@types/cms/landingPageData'
import OutletTeaserBox from '~/components/landing-page/OutletTeaserBox.vue'
import Logo from '~/components/logo/Logo.vue'
import ManualPictureCrop from '../ui/ManualPictureCrop/ManualPictureCrop.vue'

export default defineComponent({
  name: 'OutletTeaser',
  components: {
    VueSlickCarousel,
    OutletTeaserBox,
    Logo,
    ManualPictureCrop
  },
  props: {
    data: {
      type: Object as PropType<OutletTeaserData>,
      required: true
    },
    variant: {
      type: String as PropType<'' | 'ab-c' | 'ab-1' | 'ab-2' | 'ab-3'>,
      default: ''
    }
  },
  setup({data, variant}) {

    const showTwoBackgroundImages = true

    return {
      teaserImageDesktop: computed(() => {
        return data.teaserImageDesktop || data.teaserImageSingle
      }),
      showTwoBackgroundImages,
      leftBackgroundImageCss: computed(() => {
        if(showTwoBackgroundImages) {
          return ['lg:w-full']
        }

        return ['w-full', 'w-full']
      }),
      leftBackgroundImageWrapperCss: computed(() => {
        if(showTwoBackgroundImages) {
          return ['md:w-1/2']
        }

        return []
      }),
      dynamicProperties: computed(() => {
        if (variant === 'ab-1') {
          return {paragraphColor: 'text-white'}
        }

        return {}
      }),
      teaserBoxHeadlineCss: computed(() => {
        if (variant === 'ab-1') {
          return ['text-3xl']
        }

        return ['lg:px-16', 'text-2xl', 'lg:text-3xl']
      }),
      teaserBoxWrapperCss: computed(() => {
        if (variant === 'ab-1') {
          return ['text-white']
        }

        return ['justify-center', 'items-center', 'text-center']
      }),
      teaserBoxCss: computed(() => {
        if (variant === 'ab-1') {
          return ['text-white']
        }

        return ['bg-white']
      }),
      teaserCtaCss: computed(() => {
        if(variant === 'ab-1') {
          return ['bg-white', 'text-black']
        }

        return ['bg-black', 'text-white']
      }),
      headline: computed(() => data.outletTeaserBox[0].headline),
      subline: computed(() => data.outletTeaserBox[0].subline),
      cta: computed(() => data.outletTeaserBox[0].cta),
      responsiveSliderConfig: [
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 6,
            infinite: true,
            autoplay: true
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            autoplay: true
          }
        }
      ]
    }
  }
})
