
import {
  computed,
  defineComponent,
  PropType,
  useStore,
  useRouter,
  useContext
} from '@nuxtjs/composition-api'
import {DealPreviewData} from '~/@types/cms/landingPageData'

export default defineComponent({
  props: {
    dealPreview: {
      type: Object as PropType<DealPreviewData>,
      required: true
    }
  },
  setup({dealPreview}) {
    const store = useStore()
    const router = useRouter()
    const {i18n} = useContext()

    const totalDeals = computed(
      () => store.getters['deals/activeDeals'](dealPreview.category).length ?? 0
    )

    const ctaText = computed(() =>
      totalDeals.value > 0 && totalDeals.value > 1
        ? i18n.t('landing.cta_discover_sales', {count: totalDeals.value})
        : totalDeals.value === 1
        ? i18n.t('landing.cta_discover_sale', {count: totalDeals.value})
        : i18n.t('landing.cta_discover')
    )

    const goToSignIn = () => {
      router.push('/signin')
    }

    return {
      totalDeals,
      ctaText,
      goToSignIn
    }
  }
})
