
import {
  computed,
  defineComponent,
  PropType,
  useAsync,
  useRoute,
} from '@nuxtjs/composition-api'
import USP from '~/components/usps/USP.vue'
import { useCmsStories } from '~/composables/useCms'

export default defineComponent({
  name: 'USPs',
  components: {
    USP,
  },
  props: {
    textSize: {
      type: String as PropType<'small' | 'medium' | 'big'>,
      default: 'medium',
    },
    textColor: {
      type: String as PropType<'white' | 'gray' | 'black'>,
      default: 'white',
    },
    uppercase: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  setup() {
    const { data: uspData, fetchByFolder } = useCmsStories('usps')
    const route = useRoute()

    useAsync(
      async () =>
        await fetchByFolder({
          folder: 'usps',
          callingComponent: 'USPList.vue',
          route: route.value.fullPath,
          cacheOptions: { ttl: 3600, cacheKeyPrefix: 'USPS' },
        })
    )
    return {
      uspData: computed(() => uspData.value?.[0]?.content?.usp),
    }
  },
})
