import {seoFormatter} from "@scayle/storefront-nuxt2";
import {useContext, useMeta} from "@nuxtjs/composition-api";

export default function useHomeSeo() {
  const {$currentShop, i18n} = useContext()
  const [_, areaServed] = $currentShop.locale.split('-')

  const metaTags = seoFormatter({
    robots: 'index,follow',
    description: i18n.t('seo.description')
  })

  useMeta({
    title: i18n.t('seo.title'),
    // Use only `seoFormatter().meta` as we already generate the 'canonical' from `useLayoutMeta`
    // This prevents adding the 'canonical' link twice
    meta: metaTags.meta,
    script: [
      {
      type: "application/ld+json",
      json: {
          "@context": "https://schema.org",
          "@type": "Organization",
          name: "About You Outlet",
          alternateName: "AY Outlet",
          url: `https://${$currentShop.domain}`,
          logo: "https://a.storyblok.com/f/153958/2720x360/5faaa68794/logo_schwarz_aboutyou_outlet.png/m/fit-in/400x0",
          contactPoint: {
            "@type": "ContactPoint",
            "telephone": i18n.t('seo.ldjson.phone'),
            "contactType": "customer service",
            "contactOption": "TollFree",
            areaServed,
            "availableLanguage": i18n.t('seo.ldjson.available_language')
          },
          sameAs: [
            "https://www.facebook.com/aboutyououtlet/ ",
            "https://www.instagram.com/aboutyou_outlet/ ",
            "https://www.youtube.com/channel/UCBXVazjXMJjPqvj1RPi-9Rg "
          ]
        }
      }
    ]
  })
}
