
import { defineComponent, PropType } from '@nuxtjs/composition-api'
import Link from '~/components/ui/links/Link.vue'

export default defineComponent({
  name: 'OutletTeaserBox',
  components: {
    Link,
  },
  props: {
    subline: {
      type: Object as PropType<Object>,
      required: true,
    },
    // cta: {
    //   type: String as PropType<string>,
    //   required: true,
    // },
    paragraphColor: {
      type: String as PropType<string>,
      default: () => 'text-gray-600 text-sm mb-4 lg:mb-0'
    }
  },
})
