
import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'USP',
  props: {
    icon: {
      type: Object,
      required: true,
    },
    title: {
      type: String as PropType<string>,
      required: true,
    },
    size: {
      type: String as PropType<'small' | 'medium' | 'big'>,
      default: 'medium',
    },
    color: {
      type: String as PropType<'white' | 'gray' | 'black'>,
      default: 'black',
    },
    uppercase: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  setup({ color, size, uppercase }) {
    return {
      iconColor: computed(() => (color === 'white' ? 'invert' : 'invert-0')),
      textColor: computed(() =>
        color === 'white'
          ? 'text-white/80'
          : color === 'gray'
          ? 'text-gray-600'
          : 'text-black'
      ),
      textSize: computed(() =>
        size === 'small'
          ? 'text-2xs'
          : size === 'medium'
          ? 'text-sm'
          : 'text-base'
      ),
      textTransform: computed(() => (uppercase ? 'uppercase' : 'normal-case')),
    }
  },
})
